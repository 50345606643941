import { environment } from './environments/environment';

if (environment.hmr) {
  (window as any).isHMR = true;
}

if (environment.ngServe) {
  (window as any).isNgServer = true;
}

// needed for Dragula
(window as any).global = window;

// needed for monaco editor
new Promise((res, rej) => {

  if ((window as any).define){
    return res('monaco loader already exist');
  }

  const loaderScript = document.createElement('script');
  loaderScript.type = 'text/javascript';
  loaderScript.src = '/assets/monaco-editor/min/vs/loader.js';
  loaderScript.addEventListener('load', res);
  loaderScript.addEventListener('error', rej);
  document.body.appendChild(loaderScript);
})

  .then(() =>
    import('./bootstrap'))

  .catch(err => {
    console.error(err);
  });
